import React, { useRef, useEffect, useContext } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { mapContext } from "../context/mapContext";
import MapPopup from "./MapPopup";

import "../styles/mapbox-element-customisation.css";

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoic3RhY2h5cyIsImEiOiJjbGh4czk2eDMwdnA0M2ZtZDV0NGxnMXpmIn0.0qycMp_Xt8wk1wvzQlDHcg";

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const MapContainer = () => {
  const mapContainer = useRef(null);
  const { mapRef, popupLngLat, popupContent, setPopupContent, setPopupLngLat } = useContext(
    mapContext
  );
  const onPopupClose = () => {
    setPopupLngLat(null);
    setPopupContent([]);
  };

  useEffect(() => {
    if (mapRef.current) return; // initialize map only once
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/stachys/ckca6it1z4ky41io1azsxuuv7/",
      center: [-3.48, 55.79],
      zoom: 4.1,
    });

    mapRef.current.on("load", () => {
      // Add geolocate control to the map.
      var geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });
      mapRef.current.addControl(geolocate);

      var nav = new mapboxgl.NavigationControl();
      mapRef.current.addControl(nav, "top-left");
    });
  });

  return (
    <>
      {popupLngLat && (
        <MapPopup lngLat={popupLngLat} onClose={onPopupClose}>
          {popupContent}
        </MapPopup>
      )}
      <div ref={mapContainer} className="map-container" />
    </>
  );
};

export default MapContainer;
