import React, { Component, createRef } from 'react';

const clamp = (min: number, max: number, val: number): number => Math.min(Math.max(min, val), max);
// const gtZero = (val: number): number => Math.max(val, 0);
// const round = (val: number, dp: number) => parseFloat(val.toFixed(dp));

// Use Object.fromEntries when available
const filter = (object: Object, fn: (key: string, val: any) => boolean) => Object.fromEntries(Object.entries(object).filter(([key, val]) => fn(key, val)));

type Marker = { start: number, end?: number, className?: string };

export type ScrubberProps = {
    className?: string,
    value: number;
    step: number;
    min: number;
    max: number;
    bufferPosition?: number;
    vertical?: boolean;
    onScrubStart?: (value: number) => void;
    onScrubEnd?: (value: number) => void;
    onScrubChange?: (value: number) => void;
    markers?: Array<number | Marker>;
    [key: string]: any;
};

type Nullable<T> = T | null;

type ScrubberState = {
    seeking: boolean;
    mouseX: Nullable<number>;
    mouseY: Nullable<number>;
    prevMouseX: Nullable<number>;
    prevMouseY: Nullable<number>;
    touchId: Nullable<number>;
    touchX: Nullable<number>;
    touchY: Nullable<number>;
    prevTouchX: Nullable<number>;
    prevTouchY: Nullable<number>;
    hover: boolean;
};

export class Scrubber extends Component<ScrubberProps> {
    barRef = createRef<HTMLDivElement>();
    state: ScrubberState = {
        seeking: false,
        mouseX: null,
        mouseY: null,
        prevMouseX: null,
        prevMouseY: null,
        touchId: null,
        touchX: null,
        touchY: null,
        prevTouchX: null,
        prevTouchY: null,
        hover: false,
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleSeekEnd);
        window.addEventListener('touchmove', this.handleTouchMove, { passive: false });
        window.addEventListener('touchend', this.handleTouchEnd, { passive: false });
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleSeekEnd);
        window.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('touchend', this.handleTouchEnd);
    }

    getPositionFromMouseX = (end: number) => {
        const { value, step } = this.props;
        if (end) { return value };
        const { min, max } = this.props; // for clamping
        const { mouseX, touchX, prevMouseX, prevTouchX } = this.state;
        const cursor = typeof touchX === 'number' ? touchX : mouseX || 0;
        const prevCursor = (typeof touchX === 'number' ? prevTouchX : prevMouseX) || cursor;
        const diff = cursor - prevCursor
        const scaledDiff = -step * Math.sign(diff);
        const newValue = clamp(min, max, value + scaledDiff);
        return newValue;
    }

    getPositionFromMouseY = (end: number) => {
        const { value, step } = this.props;
        if (end) { return value };
        const { min, max } = this.props; // for clamping
        const { mouseY, touchY, prevMouseY, prevTouchY } = this.state;
        const cursor = typeof touchY === 'number' ? touchY : mouseY || 0;
        const prevCursor = (typeof touchY === 'number' ? prevTouchY : prevMouseY) || cursor;
        const diff = cursor - prevCursor
        const scaledDiff = -step * Math.sign(diff);
        const newValue = clamp(min, max, value + scaledDiff);
        return newValue;
    }

    getPositionFromCursor = (end: number = 0) => {
        const { vertical } = this.props;
        return vertical ? this.getPositionFromMouseY(end) : this.getPositionFromMouseX(end);
    }

    handleMouseMove = (e: MouseEvent) => {
        this.setState({
            prevMouseX: this.state.mouseX,
            prevMouseY: this.state.mouseY,
            mouseX: e.pageX,
            mouseY: e.pageY
        }, () => {
            if (this.state.seeking && this.props.onScrubChange) {
                this.props.onScrubChange(this.getPositionFromCursor());
            }
        });
    }

    handleTouchMove = (e: TouchEvent) => {
        if (this.state.seeking) {
            e.preventDefault();
        }

        const touch = Array.from(e.changedTouches).find(t => t.identifier === this.state.touchId);
        if (touch) {
            this.setState({
                prevTouchX: this.state.touchX,
                prevTouchY: this.state.touchY,
                touchX: touch.pageX,
                touchY: touch.pageY
            }, () => {
                if (this.state.seeking && this.props.onScrubChange) {
                    this.props.onScrubChange(this.getPositionFromCursor());
                }
            });
        }
    }

    handleSeekStart = (e: React.MouseEvent<HTMLDivElement>) => {
        this.setState({ seeking: true, mouseX: e.pageX, mouseY: e.pageY }, () => {
            if (this.props.onScrubStart) {
                this.props.onScrubStart(this.getPositionFromCursor());
            }
        });
    }

    handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        const touch = e.changedTouches[0];
        this.setState({ hover: true, seeking: true, touchId: touch.identifier, touchX: touch.pageX, touchY: touch.pageY }, () => {
            if (this.props.onScrubStart) {
                this.props.onScrubStart(this.getPositionFromCursor());
            }
        });
    }

    handleSeekEnd = () => {
        if (this.state.seeking) {
            if (this.props.onScrubEnd) {
                this.props.onScrubEnd(this.getPositionFromCursor(1));
            }
            this.setState({ seeking: false, mouseX: null, mouseY: null });
        }
    }

    handleTouchEnd = (e: TouchEvent) => {
        const touch = Array.from(e.changedTouches).find(t => t.identifier === this.state.touchId);
        if (touch && this.state.seeking) {
            if (this.props.onScrubEnd) {
                this.props.onScrubEnd(this.getPositionFromCursor());
            }
            this.setState({ hover: false, seeking: false, touchX: null, touchY: null,
                prevTouchX: null, prevTouchY: null, touchId: null });
        }
    }

    render() {
        const { className, value, vertical } = this.props;

        const classes = ['scrubber', vertical ? 'vertical' : 'horizontal'];
        if (this.state.hover) classes.push('hover');
        if (this.state.seeking) classes.push('seeking');
        if (className) classes.push(className);

        const propsKeys = [
            'className',
            'value',
            'min',
            'max',
            'bufferPosition',
            'vertical',
            'onScrubStart',
            'onScrubEnd',
            'onScrubChange',
            'markers',
        ];

        const customProps = filter(this.props, (key) => !propsKeys.includes(key));
        const displayDp = Math.log10(1 / this.props.step);

        return (
            <div
                onMouseDown={this.handleSeekStart}
                onTouchStart={this.handleTouchStart}
                onTouchEnd={e => e.preventDefault()}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                {...customProps}
                className={classes.join(' ')}
            >
                <div>{value.toFixed(displayDp)}</div>
                {/* <input type="number" value={value.toFixed(displayDp)} onChange={(e) => { if (this.props.onScrubChange) {this.props.onScrubChange(parseFloat(e.target.value)) })}}/> */}
            </div>
        );
    }
};
