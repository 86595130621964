import React, { useState } from "react";
import { MAPBOX_ACCESS_TOKEN } from "../MapContainer";

const getPlaceSuggestions = async (searchStr, setData) => {
  let geocoding_url = new URL(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchStr}.json`
  );

  geocoding_url.searchParams.append("access_token", MAPBOX_ACCESS_TOKEN);
  geocoding_url.searchParams.append("country", "GB");

  await fetch(geocoding_url)
    .then((res) => res.json())
    .then((data) => {
      // console.log("success", data);
      setData(data.features);
    })
    .catch((err) => {
      console.log("error", err);
      setData([]);
    });
};

const PlaceSearchInput = ({ setPlaceSearchFeatures }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      // console.log(searchQuery);
      getPlaceSuggestions(searchQuery, setPlaceSearchFeatures);
    }
  };

  return (
    <div className="place-search-input">
      <input
        type="text"
        placeholder="Search places"
        onKeyUp={handleKeyUp}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      ></input>
      <button
        className="go-button"
        onClick={() => {
          getPlaceSuggestions(searchQuery, setPlaceSearchFeatures);
        }}
      >
        Go
      </button>
    </div>
  );
};

const LocationBox = ({ name, address, lat, lon, handleClick }) => {
  return (
    <div
      className="location-box"
      onClick={() => {
        handleClick({ lon, lat, name, address });
      }}
    >
      <div className="location-name">{name}</div>
      <div className="location-address">{address}</div>
    </div>
  );
};

const PlaceSearchResults = ({ features, handleClick }) => {
  return (
    <div className="location-boxes-container">
      {features.map((d, idx) => (
        <LocationBox
          name={d.text}
          address={d.place_name}
          key={idx}
          lat={d.geometry.coordinates[1]}
          lon={d.geometry.coordinates[0]}
          handleClick={handleClick}
        />
      ))}
    </div>
  );
};

const PlaceSearch = ({ handleLocationClick }) => {
  const [placeSearchFeatures, setPlaceSearchFeatures] = useState([]);

  return (
    <>
      <PlaceSearchInput setPlaceSearchFeatures={setPlaceSearchFeatures} />
      {!!placeSearchFeatures.length && (
        <PlaceSearchResults features={placeSearchFeatures} handleClick={handleLocationClick} />
      )}
    </>
  );
};

export default PlaceSearch;
