import gql from "graphql-tag";
import React, { useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";

const TTY_FOR_SPECIES = gql`
  query ThroughTheYear($speciesBinomialName: String) {
    through_the_years(query: { name: $speciesBinomialName }, limit: 1) {
      _id
      family
      commonName
      name
      wavg
      monthlyCounts
      guid
    }
  }
`;

const TTYCanvas = ({ width, data }) => {
  const ref = useRef(null);
  const canvasWidth = width || 30;
  const canvasHeight = 30;

  useEffect(() => {
    if (ref && ref.current) {
      const canvas = ref.current;
      const ctx = canvas.getContext("2d");
      drawTTYLine(canvas, ctx, data.monthlyCounts);
    }
  });

  return (
    <div className="canvas-container">
      <canvas
        id={data.guid || ""}
        key={data.guid || data.name}
        ref={ref}
        width={canvasWidth}
        height={canvasHeight}
        className="tty-canvas"
      />
    </div>
  );
};

export const drawTTYLine = (canvas, ctx, speciesCounts) => {
  const maxCount = Math.max(...speciesCounts);
  const scaledCounts = speciesCounts.map((d) => (d / maxCount) * 0.9);
  const HEIGHT = canvas.height;
  const WIDTH = canvas.width;
  // console.log(WIDTH, HEIGHT);

  const now = new Date();
  const today = { day: now.getDate(), month: now.getMonth() };
  let ax, ay, bx, by, cx, cy;
  const steps = 12;

  // Clear canvas.
  ctx.clearRect(0, 0, WIDTH, HEIGHT);
  ctx.save();
  const jfact = HEIGHT / WIDTH;

  ctx.scale(WIDTH, HEIGHT);
  ctx.lineCap = "round";
  ctx.lineWidth = 0.0005;
  ctx.strokeStyle = "#aaa";

  // let axprev = -2 / 12 + 1 / 24;
  // let ayprev = 1 - scaledCounts[10];
  let bxprev = -1.5 / 12 + 1 / 24;
  let byprev = 1 - scaledCounts[11];
  let cxprev = -1 / 12 + 1 / 24;
  let cyprev = 1 - scaledCounts[11];

  ctx.moveTo(-2 / 12, 1 - scaledCounts[10]);
  ctx.lineWidth = 0.005;

  for (let idx = 0; idx < 13; idx++) {
    ax = cxprev + (cxprev - bxprev);
    ay = cyprev + (cyprev - byprev);
    bx = (idx - 0.5) / steps + 1 / 24;
    by = 1 - scaledCounts[idx % 12];
    cx = idx / steps + 1 / 24;
    cy = 1 - scaledCounts[idx % 12];

    ctx.bezierCurveTo(ax, ay, bx, by, cx, cy);

    // axprev = ax;
    // ayprev = ay;
    bxprev = bx;
    byprev = by;
    cxprev = cx;
    cyprev = cy;
  }
  ctx.lineTo(1, 1);
  ctx.lineTo(0, 1);
  ctx.closePath();
  ctx.fillStyle = `hsl(158, 40%, 75%)`;
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo((today.month - 0.5 + today.day / 30) / steps + 1 / 24, 1);
  ctx.lineTo((today.month - 0.5 + today.day / 30) / steps + 1 / 24, jfact * 0.9);
  ctx.strokeStyle = "hsl(158, 20%, 50%)";
  ctx.stroke();
  ctx.closePath();
  ctx.restore();
};

const ThroughTheYear = ({ speciesBinomialName, parentWidth, setUKCount }) => {
  const variables = {
    speciesBinomialName: speciesBinomialName,
  };

  const { data, error, loading } = useQuery(TTY_FOR_SPECIES, {
    variables,
  });

  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>encountered an error: {error.message}</div>;
  }
  if (!data.through_the_years.length) {
    return <></>;
  }
  setUKCount(data.through_the_years[0].monthlyCounts.reduce((a, b) => a + b, 0));
  return <TTYCanvas width={parentWidth} data={data.through_the_years[0]} />;
};

export default ThroughTheYear;
