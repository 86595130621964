import React, { useContext } from "react";

import * as Realm from "realm-web";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";

import MapContainer from "./components/MapContainer";
import Filters from "./components/Filters";

import "./styles/App.css";
import "./styles/owstyle.css";
import { MapProvider, mapContext } from "./context/mapContext";
import SightingsExplorer from "./components/explorer/ExplorerPanel";

const REALM_APP_ID = "test1-cugzx";

// Connect to MongoDB Realm app
const app = new Realm.App(REALM_APP_ID);
// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  if (!app.currentUser) {
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    // An already logged in user's access token might be stale. Tokens must be refreshed after
    // 30 minutes. To guarantee that the token is valid, we refresh the user's access token.
    await app.currentUser.refreshAccessToken();
  }
  return app.currentUser.accessToken;
}
// Configure the ApolloClient to connect to your app's GraphQL endpoint
const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/${REALM_APP_ID}/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache(),
});

const AppWithApollo = () => {
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

const ShowHideExplorer = () => {
  const { showHideExplorer, explorerFlexGrow } = useContext(mapContext);
  const shStyle = {
    transform: `translate(var(--show-hide-offset-x), var(--show-hide-offset-y)) rotate(calc(var(--show-hide-rotate) + ${explorerFlexGrow *
      180}deg))`,
  };
  return <button className="showHide" onClick={showHideExplorer} style={shStyle} />;
};

function App() {
  const queryParameters = new URLSearchParams(window.location.search);

  return (
    <MapProvider>
      <div className="App">
        <SightingsExplorer />
        <div className="map-area-container">
          <ShowHideExplorer />
          <MapContainer />
          <Filters categoryType={queryParameters.get("categoryType") || "placetype"} />
        </div>
      </div>
    </MapProvider>
  );
}

export default AppWithApollo;
