import React, { useContext, useEffect, useRef } from "react";
import { mapContext } from "../context/mapContext";
import mapboxgl from "mapbox-gl";

export const MapPopup = ({ children, lngLat, onClose }) => {
  const popupRef = useRef(null);
  const { mapRef } = useContext(mapContext);
  const map = mapRef.current;
  useEffect(() => {
    const popup = new mapboxgl.Popup({})
      .setLngLat(lngLat)
      .setDOMContent(popupRef.current)
      .addTo(map);

    popup.on("close", onClose);
    popup.off('close', onClose);
    return popup.remove;
  }, [children, lngLat]);

  return (
      <div ref={popupRef}>{children}</div>

  );
};

export default MapPopup;
