import React from "react";
import { TOPIC_COLOURS } from "./topic_constants";
import { PLACETYPE_COLOURS } from "./constants";
import MapPopupContent from "../components/MapPopupContent";

const colorMaps = {
  topic: TOPIC_COLOURS,
  placetype: PLACETYPE_COLOURS,
};

const geojsonify = (place_features) => ({
  type: "FeatureCollection",
  features: place_features,
});

export const addLayerToMap = (
  map,
  place_features,
  layerId,
  layerType,
  setPopupLngLat,
  setPopupContent,
  showExplorer
) => {
  if (map && map.getLayer(layerId)) {
    return;
  }
  const sourceId = `places-${layerType}-${layerId}`;
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: "geojson",
      data: geojsonify(place_features),
    });
  }

  map.addLayer({
    id: layerId,
    type: "circle",
    source: sourceId,
    paint: {
      "circle-color": colorMaps[layerType][layerId],
      "circle-stroke-width": 0,
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        2,
        ["get", "markersize"],
        15,
        ["*", ["get", "markersize"], 5],
      ],
      "circle-opacity": 0.9,
    },
    visibility: "none",
  });

  makeClickable(map, layerId, layerType, setPopupLngLat, setPopupContent, showExplorer);
};

const makeClickable = (map, layerId, layerType, setPopupLngLat, setPopupContent, showExplorer) => {
  const layerColors = colorMaps[layerType];

  map.on("click", layerId, function(e) {
    const label = (
      <MapPopupContent
        feature={e.features[0]}
        layerColors={layerColors}
        showExplorer={showExplorer}
      />
    );
    setPopupContent(label);
    setPopupLngLat(e.features[0].geometry.coordinates);
  });

  // Change the cursor to a pointer when the mouse is over the places layer.
  map.on("mouseenter", layerId, function() {
    map.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", layerId, function() {
    map.getCanvas().style.cursor = "";
  });
};

export const drawRadCircle = (lat, lon, km, mapRef) => {
  if (mapRef.current.style.getLayer("rad-circle")) {
    mapRef.current.removeLayer("rad-circle");
    mapRef.current.removeSource("rad-circle-source");
  }
  if (!lat || !lon || !km) {
    return;
  }
  var radCircleGeoJson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: { name: "areamarker" },
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
      },
    ],
  };

  radCircleGeoJson.features[0].geometry.coordinates = [lon, lat];
  const metersToPixelsAtMaxZoom = (meters, latitude) =>
    meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
  // add it to the map
  mapRef.current.addSource("rad-circle-source", { type: "geojson", data: radCircleGeoJson });
  mapRef.current.addLayer({
    id: "rad-circle",
    type: "circle",
    source: "rad-circle-source",
    paint: {
      "circle-color": "#40bfbf",
      "circle-opacity": 0.2,
      "circle-radius": {
        stops: [
          [0, 0],
          [
            20,
            metersToPixelsAtMaxZoom(
              km * 1000,
              radCircleGeoJson.features[0].geometry.coordinates[1]
            ),
          ],
        ],
        base: 2,
      },
    },
  });
};
