import React, { createContext, useState, useRef } from "react";

export const mapContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
export const MapProvider = ({ children }) => {
  const Provider = mapContext.Provider;
  // Map.
  const mapRef = useRef(null);
  // Popup.
  const [popupContent, setPopupContent] = useState([]);
  const [popupLngLat, setPopupLngLat] = useState(null);
  // Explorer.
  const [explorerFlexGrow, setExplorerFlexGrow] = useState(0);
  const [explorerType, setExplorerType] = useState(null);
  const [explorerProps, setExplorerProps] = useState({});

  const showHideExplorer = () => {
    setExplorerFlexGrow(explorerFlexGrow ? 0 : 1);
    for (let i = 0; i < 10; i += 1) {
      setInterval(() => {
        mapRef.current.resize();
      }, 10);
    }
  };
  const showExplorer = (type, props) => {
    if (type) {setExplorerType(type)};
    if (props) {setExplorerProps(props)};
    setExplorerFlexGrow(1);
    for (let i = 0; i < 10; i += 1) {
      setInterval(() => {
        mapRef.current.resize();
      }, 10);
    }
  };
  const hideExplorer = () => {
    setExplorerFlexGrow(0);
    for (let i = 0; i < 10; i += 1) {
      setInterval(() => {
        mapRef.current.resize();
      }, 10);
    }
  };

  return (
    <Provider
      value={{
        popupContent,
        setPopupContent,
        popupLngLat,
        setPopupLngLat,
        explorerFlexGrow,
        showHideExplorer,
        showExplorer,
        hideExplorer,
        mapRef,
        explorerType,
        setExplorerType,
        explorerProps,
        setExplorerProps,
      }}
    >
      {children}
    </Provider>
  );
};
