import React, { useMemo, useState } from "react";
import "../../../styles/info-about-species.css";

const getInatImageURL = (binName) => {
  var url = new URL("https://www.inaturalist.org/observations.json");
  url.searchParams.append("swlat", 49.959999905);
  url.searchParams.append("swlng", -7.57216793459);
  url.searchParams.append("nelat", 58.6350001085);
  url.searchParams.append("nelng", 1.68153079591);
  url.searchParams.append("per_page", 50);
  url.searchParams.append("order_by", "observed_on");
  url.searchParams.append("order", "desc");
  url.searchParams.append("taxon_name", binName);
  url.searchParams.append("quality_grade", "research");
  url.searchParams.append("photo_license", "any");
  url.searchParams.append("has[]", "photos");
  return url;

};

const InfoAboutSpecies = ({ speciesData, inProp }) => {
  const [imageData, setImageData] = useState(false);
  // const [imageData, setImageData] = useState(null);
  
  useMemo(() => {
    if (!inProp) {
      return false;
    }
    const url = getInatImageURL(speciesData.name);
    const fetchData = async () => {
      await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        data.sort(function(a, b) {
          return (
            b["identifications_count"] +
            2 * b["faves_count"] -
            (2 * a["faves_count"] + a["identifications_count"])
          );
        });
        // console.log("success", data);
        setImageData(data[0]);
      });
    };
    fetchData().catch((err) => {
      console.log("error", err);
      setImageData({ photos: [{ medium_url: "/img/ow3_1.png" }] });
    });
  }, [inProp, speciesData]);

  if (!imageData || !inProp ) {return <></>;}
  return (
    <div className="butterfly-further-info">
      <div className="species-info-links">
        <div
          className="bf-link bf-link-wiki"
          onClick={() =>
            window.open(
              `https://en.wikipedia.org/wiki/${speciesData.name.replace(" ", "_")}`,
              "_system"
            )
          }
        ></div>
        <div
          className="bf-link bf-link-nbn"
          onClick={() =>
            window.open(`https://species.nbnatlas.org/species/${speciesData.guid}`, "_system")
          }
        ></div>
        <div
          className="bf-link bf-link-ecosia"
          onClick={() =>
            window.open(
              `https://www.ecosia.org/search?q=${speciesData.name.replace(" ", "+")}`,
              "_system"
            )
          }
        ></div>
      </div>
      {imageData && (
        <div
          style={{ backgroundImage: `url(${imageData.photos[0].medium_url})` }}
          className="species-main-image"
        >
          <div className="bin-fam-names">
            <div>
              <i>{speciesData.name}</i>
            </div>
            <div>
              <b>{speciesData.family}</b>
            </div>
          </div>
          <span className="image-credit">
            image by{" "}
            <span
              className="jsa"
              onClick={() =>
                window.open(`https://www.inaturalist.org/people/${imageData.user_login}`, "_system")
              }
            >
              {imageData.user_login}
            </span>
            ,{" "}
            <span className="jsa" onClick={() => window.open(imageData.uri, "_system")}>
              iNaturalist
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default InfoAboutSpecies;
