import React, { useState } from "react";
import { Scrubber } from "./scrubber.tsx";

export const ScrubbableInput = ({ startValue, onScrubEnd, onScrubChange, step, min, max }) => {
    const [scrubberState, setScrubberState] = useState({
      value: startValue,
      state: "None",
    });
  
    const handleScrubStart = (value) => {
      setScrubberState({ value, state: "Scrub Start" });
    };
  
    const handleScrubEnd = (value) => {
      setScrubberState({ value, state: "Scrub End" });
      if (onScrubEnd) {onScrubEnd(value)}
    };
  
    const handleScrubChange = (value) => {
      setScrubberState({ value, state: "Scrub Change" });
      if (onScrubChange) {onScrubChange(value)}
    };
  
    return (
      <Scrubber
        step={step || 0.1}
        value={scrubberState.value}
        className={"scrubbable-number"}
        onScrubStart={handleScrubStart}
        onScrubEnd={handleScrubEnd}
        onScrubChange={handleScrubChange}
        vertical
        min={min || 0}
        max={max == null ? 1000 : max}
      />
    );
  };