import React, { useContext, useState } from "react";
import { mapContext } from "../../context/mapContext";
import Hotspots from "./Hotspots";
import PlaceSearch from "./PlaceSearch";
import MeetInTheMiddle from "./MeetInTheMiddle";
import SightingsExplorer from "./SightingsExplorer";

import "../../styles/explorer-panel.css";
import "../../styles/place-search.css";

const ExplorerPanel = () => {
  const [kmRadius, setKmRadius] = useState(1);
  const [month, setMonth] = useState("");
  const [taxon, setTaxon] = useState("ALL_SPECIES");
  const {
    explorerFlexGrow,
    explorerType,
    explorerProps,
    setExplorerProps,
    mapRef,
    setPopupContent,
    setPopupLngLat,
  } = useContext(mapContext);
  const { lat, lon, site } = explorerProps;

  const zoomToLonLat = ({ lon, lat }) => {
    mapRef.current.flyTo({
      center: [lon, lat],
      zoom: 13,
    });
  };

  const doit =  (e) => {
    setExplorerProps({...explorerProps, lat: e.lngLat.lat, lon: e.lngLat.lng, site: 'clicked location' });      
  };
  const handleNewLocation = (listeningForClick) => {
    if (listeningForClick) {
      mapRef.current.once("click", doit);
    } else {
      mapRef.current.off("click", doit);
    }
  };

  return (
    <div className="explorer-panel-container" style={{ flexGrow: explorerFlexGrow }}>
      {!explorerType && <div>Nothing yet</div>}
      {explorerType === "sightings" && (
        <SightingsExplorer
          lat={lat}
          lon={lon}
          site={site}
          mapRef={mapRef}
          setPopupContent={setPopupContent}
          setPopupLngLat={setPopupLngLat}
          kmRadius={kmRadius}
          setKmRadius={setKmRadius}
          taxon={taxon}
          setTaxon={setTaxon}
          month={month}
          setMonth={setMonth}
          handleNewLocation={handleNewLocation}
        />
      )}
      {explorerType === "hotspots" && <Hotspots />}
      {explorerType === "search" && <PlaceSearch handleLocationClick={zoomToLonLat} />}
      {explorerType === "isochrone" && <MeetInTheMiddle mapRef={mapRef} />}
    </div>
  );
};

export default ExplorerPanel;
