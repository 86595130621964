import React, { useState, useEffect } from "react";
import PlaceSearch from "./PlaceSearch";
import { ScrubbableInput } from "./GenericComponents/ScrubbableInput";
import { MAPBOX_ACCESS_TOKEN } from "../MapContainer";
import { polygon as tpolygon, intersect } from "turf";

const isocRgbs = [
  [89, 13, 13],
  [26, 89, 13],
  [13, 38, 89],
  [85, 13, 89],
  [13, 89, 78],
  [191, 64, 64],
  [85, 191, 64],
  [64, 106, 191],
  [191, 64, 127],
];
const getIsocColour = (index, alpha = 1) => {
  const isocColour = isocRgbs[index % isocRgbs.length];
  if (alpha >= 0 && alpha < 1 ) {
    return `rgba(${isocColour[0]}, ${isocColour[1]}, ${isocColour[2]}, ${alpha})`
  }
  return `rgb(${isocColour[0]}, ${isocColour[1]}, ${isocColour[2]})`
}


const AddPlaceBox = ({ isochronePlaces, setIsochronePlaces, getIdAndIncrement }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [minutes, setMinutes] = useState(45);

  const handleLocationClick = (icPlace, minutes) => {
    setSearchOpen(false);
    // console.log(icPlace, minutes);
    setIsochronePlaces([
      ...isochronePlaces,
      { ...icPlace, minutes: minutes, isochroneId: getIdAndIncrement() },
    ]);
  };

  return (
    <div className="mitm-search">
      {!searchOpen && (
        <button
          className="add-place go-button x-button"
          onClick={() => {
            setSearchOpen(!searchOpen);
          }}
        />
      )}
      {searchOpen && (
        <div>
          <ScrubbableInput
            startValue={minutes}
            onScrubChange={(value) => {
              setMinutes(value);
            }}
            step={1}
            min={1}
            max={60}
          />
          minutes
        </div>
      )}
      {searchOpen && (
        <PlaceSearch
          handleLocationClick={(placeData) => {
            handleLocationClick(placeData, minutes);
          }}
        />
      )}
    </div>
  );
};

const getIsochroneData = async (icPlaceData, polygons, setPolygons, mapRef) => {
  const { lat, lon, minutes, isochroneId } = icPlaceData;
  var mbIsochroneUrl = new URL(
    `https://api.mapbox.com/isochrone/v1/mapbox/driving/${[lon, lat].join(",")}`
  );

  mbIsochroneUrl.searchParams.append("access_token", MAPBOX_ACCESS_TOKEN);
  mbIsochroneUrl.searchParams.append("contours_minutes", minutes);
  mbIsochroneUrl.searchParams.append("polygons", true);

  await fetch(mbIsochroneUrl)
    .then((res) => res.json())
    .then((data) => {
      // console.log("success", data);
      setPolygons({ ...polygons, [isochroneId]: data });
      drawIsochrone(icPlaceData, data, mapRef);
    })
    .catch((err) => {
      console.log("error", err);
    });
};

const drawIsochrone = ({ isochroneId, lon, lat }, isochroneData, mapRef) => {
  mapRef.current.addSource(`isocGeojson-${isochroneId}`, {
    type: "geojson",
    data: isochroneData,
  });

  mapRef.current.addLayer(
    {
      id: `isocL-${isochroneId}`,
      type: "line",
      source: `isocGeojson-${isochroneId}`,
      layout: {},
      paint: {
        "line-color": getIsocColour(isochroneId),
        "line-width": 2.5,
        "line-opacity": 0,
      },
    },
    "country-label"
  );

  mapRef.current.addLayer(
    {
      id: `isocF-${isochroneId}`,
      type: "fill",
      source: `isocGeojson-${isochroneId}`,
      layout: {},
      paint: {
        "fill-color": getIsocColour(isochroneId),
        "fill-opacity": 0.3,
      },
    },
    "country-label"
  );

  mapRef.current.flyTo({
    center: [lon, lat],
    zoom: 8,
  });
};

const updateIntersection = (polygons, mapRef) => {
  if (mapRef.current.style.getLayer("intersectL")) {
    mapRef.current.removeLayer("intersectL");
  }

  if (mapRef.current.style.getLayer("intersectF")) {
    mapRef.current.removeLayer("intersectF");
  }

  if (mapRef.current.getSource("intersect")) {
    mapRef.current.removeSource("intersect");
  }
  let p1, p2;
  if (Object.values(polygons).length > 1) {
    p1 = tpolygon(Object.values(polygons)[0]["features"][0]["geometry"]["coordinates"]);

    for (let i = 1; i < Object.values(polygons).length; i++) {
      p2 = tpolygon(Object.values(polygons)[i]["features"][0]["geometry"]["coordinates"]);
      p1 = intersect(p1, p2);
    }

    mapRef.current.addSource("intersect", {
      type: "geojson",
      data: p1,
    });

    mapRef.current.addLayer(
      {
        id: "intersectL",
        type: "line",
        source: "intersect",
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 2,
          "line-opacity": 1,
        },
      },
      "country-label"
    );

    mapRef.current.addLayer(
      {
        id: "intersectF",
        type: "fill",
        source: "intersect",
        layout: {},
        paint: {
          "fill-color": "#fff",
          "fill-opacity": 0.1,
        },
      },
      "country-label"
    );
  }
};

const removeIsochrone = (isochroneId, mapRef) => {
  if (mapRef.current.style.getLayer(`isocL-${isochroneId}`)) {
    mapRef.current.removeLayer(`isocL-${isochroneId}`);
  } else {
    console.log("isochrone line layer not found");
  }

  if (mapRef.current.style.getLayer(`isocF-${isochroneId}`)) {
    mapRef.current.removeLayer(`isocF-${isochroneId}`);
  } else {
    console.log("isochrone fill layer not found");
  }

  if (mapRef.current.getSource(`isocGeojson-${isochroneId}`)) {
    mapRef.current.removeSource(`isocGeojson-${isochroneId}`);
  } else {
    console.log("isochrone source not found");
  }
};

const IsochronePlace = ({
  icPlaceData,
  mapRef,
  polygons,
  setPolygons,
  isochronePlaces,
  setIsochronePlaces,
}) => {
  console.log(icPlaceData);
  useEffect(() => {
    if (!Object.keys(polygons).includes(icPlaceData.isochroneId)) {
      getIsochroneData(icPlaceData, polygons, setPolygons, mapRef);
    }
  }, []);
  return (
    <div
      className="isochrone-place-box location-box"
      style={{ background: getIsocColour(icPlaceData.isochroneId, 0.2) }}
    >
      <button
        className="remove-isochrone go-button x-button"
        onClick={() => {
          const newPolygons = { ...polygons };
          delete newPolygons[icPlaceData.isochroneId];
          removeIsochrone(icPlaceData.isochroneId, mapRef);
          setPolygons(newPolygons);
          setIsochronePlaces(
            isochronePlaces.filter((d) => d.isochroneId !== icPlaceData.isochroneId)
          );
        }}
      />
      Within {icPlaceData.minutes} minutes of {icPlaceData.name}
    </div>
  );
};

const MeetInTheMiddle = ({ mapRef }) => {
  const [isochronePlaces, setIsochronePlaces] = useState([]);
  const [polygons, setPolygons] = useState({});
  const [incrId, setIncrId] = useState(0);
  const getIdAndIncrement = () => {
    const prevIncrId = incrId;
    setIncrId(prevIncrId + 1);
    return prevIncrId;
  };

  useEffect(() => {
    updateIntersection(polygons, mapRef);
  }, [polygons]);

  return (
    <>
      <AddPlaceBox
        isochronePlaces={isochronePlaces}
        setIsochronePlaces={setIsochronePlaces}
        getIdAndIncrement={getIdAndIncrement}
      />
      {isochronePlaces.map((icPlace, idx) => (
        <IsochronePlace
          icPlaceData={icPlace}
          mapRef={mapRef}
          key={idx}
          polygons={polygons}
          setPolygons={setPolygons}
          isochronePlaces={isochronePlaces}
          setIsochronePlaces={setIsochronePlaces}
        />
      ))}
    </>
  );
};

export default MeetInTheMiddle;
