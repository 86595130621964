export const TOPIC_NAMES = {
  0: "English inland water birds",
  1: "Countryside birds",
  2: "Butterflies, southern",
  3: "Coastal birds west",
  4: "Damselflies & dragonflies",
  5: "Garden birds",
  6: "Egrets, hazel dormouse & various bats",
  7: "Woodland birds I.",
  8: "Midlands birds",
  9: "Coastal birds east",
  10: "Heathland",
  11: "Sort of wet areas",
  12: "London",
  13: "Coastal birds misc I",
  14: "Butterflies, northern",
  15: "Misc. garden creatures",
  16: "Woodland birds II",
  17: "Moths",
  18: "Meadow birds by the coast",
  19: "Urban water birds",
  20: "Birds of prey",
  21: "Butterflies, general",
  22: "Bees & Scottish mammals",
  23: "Coastal birds misc II",
  24: "Garden birds, near water",
  25: "Scottish birds",
};

export const TOPIC_TYPICAL_SPECIES = {
  0: '<i style="font-family: Alegreya Sans;">Cetti\'s Warbler &#183; Coot &#183; Greylag Goose<br>Cormorant &#183; Mute Swan &#183; Tufted Duck</i>',
  1: '<i style="font-family: Alegreya Sans;">Rook &#183; Jackdaw &#183; Woodpigeon<br>Corn Bunting &#183; Pheasant &#183; Yellowhammer</i>',
  2: '<i style="font-family: Alegreya Sans;">Meadow Brown &#183; Common Blue &#183; Marbled White<br>Small Heath &#183; Brown Argus &#183; Chalk Hill Blue</i>',
  3: '<i style="font-family: Alegreya Sans;">Fulmar &#183; Guillemot &#183; Herring Gull<br>Barnacle &#183; Shag &#183; Puffin</i>',
  4: '<i style="font-family: Alegreya Sans;">Azure Damselfly &#183; Common Darter &#183; Common Blue Damselfly<br>Large Red Damselfly &#183; Blue-tailed Damselfly &#183; Four-spotted Chaser</i>',
  5: '<i style="font-family: Alegreya Sans;">House Sparrow &#183; Blackbird &#183; Collared Dove<br>Dunnock &#183; Blue Tit &#183; Woodpigeon</i>',
  6: '<i style="font-family: Alegreya Sans;">Hazel Dormouse &#183; Lesser Horseshoe Bat &#183; Hawfinch<br>Great White Egret &#183; Greater Horseshoe Bat &#183; Cirl Bunting</i>',
  7: '<i style="font-family: Alegreya Sans;">Buzzard &#183; Two-barred Crossbill &#183; Blue Ground Beetle<br>Green Woodpecker &#183; Violet Oil-beetle &#183; Redstart</i>',
  8: '<i style="font-family: Alegreya Sans;">Willow Tit &#183; Reed Bunting &#183; Bullfinch<br>Gadwall &#183; Willow Warbler &#183; Grasshopper Warbler</i>',
  9: '<i style="font-family: Alegreya Sans;">Dunlin &#183; Redshank &#183; Grey Plover<br>Curlew &#183; Bar-tailed Godwit &#183; Dark-bellied Brent Goose</i>',
  10: '<i style="font-family: Alegreya Sans;">Silver-studded Blue &#183; Small Heath &#183; Grayling<br>Sand Lizard &#183; Wall &#183; Smooth Snake</i>',
  11: '<i style="font-family: Alegreya Sans;">Pochard &#183; Yellow-legged Gull &#183; Black-necked Grebe<br>Bittern &#183; Spotted Crake &#183; Pintail</i>',
  12: '<i style="font-family: Alegreya Sans;">Feral Pigeon/Rock Dove &#183; Ring-necked Parakeet &#183; Magpie<br>Woodpigeon &#183; Stag Beetle &#183; Carrion Crow</i>',
  13: '<i style="font-family: Alegreya Sans;">Shelduck &#183; Teal &#183; Lapwing<br>Redshank &#183; Curlew &#183; Mallard</i>',
  14: '<i style="font-family: Alegreya Sans;">Northern Brown Argus &#183; Speckled Wood &#183; High Brown Fritillary<br>White-spotted Sable &#183; Scotch Argus &#183; Large Heath</i>',
  15: '<i style="font-family: Alegreya Sans;">7-spot Ladybird &#183; Common Pipistrelle &#183; West European Hedgehog<br>Green Shieldbug &#183; Harlequin Ladybird &#183; Great Crested Newt</i>',
  16: '<i style="font-family: Alegreya Sans;">Nuthatch &#183; Coal Tit &#183; Siskin<br>Blue Tit &#183; Great Tit &#183; Dipper</i>',
  17: '<i style="font-family: Alegreya Sans;">Large Yellow Underwing &#183; Heart & Dart &#183; Brimstone Moth<br>Willow Beauty &#183; Light Brown Apple Moth &#183; Dark Arches</i>',
  18: '<i style="font-family: Alegreya Sans;">Stonechat &#183; Wheatear &#183; Meadow Pipit<br>Herring Gull &#183; Hudsonian Whimbrel &#183; Sunset Cup Coral</i>',
  19: '<i style="font-family: Alegreya Sans;">Coot &#183; Tufted Duck &#183; Mallard<br>Mute Swan &#183; Canada Goose &#183; Great Crested Grebe</i>',
  20: '<i style="font-family: Alegreya Sans;">Red Kite &#183; Peregrine &#183; Hobby<br>Little Ringed Plover &#183; Woodlark &#183; Long-eared Owl</i>',
  21: '<i style="font-family: Alegreya Sans;">Speckled Wood &#183; Green-veined White &#183; Small White<br>Meadow Brown &#183; Large White &#183; Ringlet</i>',
  22: '<i style="font-family: Alegreya Sans;">Common Carder Bee &#183; Eurasian Red Squirrel &#183; Buff-tailed Bumblebee<br>Large Red-tailed Bumblebee &#183; White/Buff-tailed Bumblebee workers &#183; Honey Bee</i>',
  23: '<i style="font-family: Alegreya Sans;">Black-tailed Godwit &#183; Pintail &#183; Ruff<br>Spoonbill &#183; Mediterranean Gull &#183; Short-eared Owl</i>',
  24: '<i style="font-family: Alegreya Sans;">Robin &#183; Blue Tit &#183; Blackcap<br>Chiffchaff &#183; Blackbird &#183; Dunnock</i>',
  25: '<i style="font-family: Alegreya Sans;">Goldeneye &#183; Tree Sparrow &#183; Goosander<br>Whooper Swan &#183; Greylag Goose &#183; Pink-footed Goose</i>',
};

export const TOPIC_COLOURS = {
  0: "#AA0DFE",
  1: "#1CBE4F",
  2: "#FC1CBF",
  3: "#782AB6",
  4: "#B00068",
  5: "#1C8356",
  6: "#d9ca68",
  7: "#B4DE49",
  8: "#16FF32",
  9: "#3283FE",
  10: "#cc4d33",
  11: "#DEA0FD",
  12: "#A6A6A6",
  13: "#325A9B",
  14: "#C075A6",
  15: "#F8A19F",
  16: "#90AD1C",
  17: "#B10DA1",
  18: "#1CFFCE",
  19: "#565656",
  20: "#85660D",
  21: "#FE00FA",
  22: "#da790b",
  23: "#2ED9FF",
  24: "#5bab98",
  25: "#dbd000",
};
