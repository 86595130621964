import React, { useEffect, useState } from "react";
import { drawRadCircle } from "../../scripts/mapUtils";
import { ScrubbableInput } from "./GenericComponents/ScrubbableInput";
import NbnSightings, { fetchNbnSightings } from "./QueryDataComponents/NbnSightings";
import InaturalistSightings, {
  fetchInatSightings,
} from "./QueryDataComponents/InaturalistSightings";
import LoadingLogo from "./LoadingLogo";

export const getNiceTaxon = (txn) => (txn === "ALL_SPECIES" ? "All species" : txn);

const TAXON_OPTIONS = {
  All: ["ALL_SPECIES"],
  Animals: ["Animals", "Mammals", "Birds", "Arthropods", "Amphibians", "Reptiles"],
  Plants: ["Plants"],
  Fungi: ["Fungi"],
};

const ExpandableDetails = ({ children, label }) => {
  return (
    <details className="explorer-input">
      <summary>{label}</summary>
      {children}
    </details>
  );
};

const RadiusInput = ({ handleRadiusChange, kmRadius }) => {
  return (
    <ExpandableDetails label={`Within ${kmRadius.toFixed(1)}km`}>
      <ScrubbableInput startValue={kmRadius || 1} onScrubEnd={handleRadiusChange} min={0} max={50} />
    </ExpandableDetails>
  );
};

const TaxonInput = ({ taxon, setTaxon }) => {
  return (
    <ExpandableDetails label={getNiceTaxon(taxon)}>
      <select
        className={"dropdown"}
        defaultValue={taxon}
        onInput={(e) => {
          setTaxon(e.target.value);
        }}
      >
        {Object.keys(TAXON_OPTIONS).map((taxonOptGroup) => (
          <optgroup label={taxonOptGroup} key={taxonOptGroup}>
            {TAXON_OPTIONS[taxonOptGroup].map((taxonOption) => (
              <option value={taxonOption} key={taxonOption}>
                {getNiceTaxon(taxonOption)}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </ExpandableDetails>
  );
};

const MonthInput = ({ month, setMonth }) => {
  var monthIds = ["", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  var monthNames = {
    "": "any month",
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };

  return (
    <ExpandableDetails label={`In ${monthNames[month]}`}>
      <select
        className={"dropdown"}
        defaultValue={month}
        onInput={(e) => {
          setMonth(e.target.value);
        }}
      >
        {monthIds.map((month) => (
          <option value={month} key={month}>
            {monthNames[month]}
          </option>
        ))}
      </select>
    </ExpandableDetails>
  );
};

const SightingsExplorer = ({
  lat,
  lon,
  site,
  kmRadius,
  setKmRadius,
  mapRef,
  setPopupContent,
  setPopupLngLat,
  taxon,
  setTaxon,
  month,
  setMonth,
  handleNewLocation,
}) => {
  const [open, setOpen] = useState(true);
  const [nbnData, setNbnData] = useState({});
  const [inatData, setInatData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    drawRadCircle(lat, lon, kmRadius, mapRef);
    // NBN data isn't shown at a specific location, so clear it if location changes.
    // Inaturalist observations are on the map so can remain. 
    setNbnData({});
    return () => drawRadCircle(null, null, null, mapRef);
  }, [lat, lon]);

  const handleRadiusChange = (km) => {
    drawRadCircle(lat, lon, km, mapRef);
    setKmRadius(km);
    setNbnData({});
  };

  const SetLocation = ({ site }) => {
    const [listeningForClick, setListeningForClick] = useState(false);    
    let bgCol = site ? '#9cb' : '#bbb';
    if (listeningForClick) bgCol = '#fd8';
    return (
      <div
        onClick={(e) => {handleNewLocation(!listeningForClick); setListeningForClick(!listeningForClick);}}
        className='explorer-input'
        style={{background: bgCol, color: '#fff', cursor: 'pointer'}}
      >
        {String.fromCharCode(8982)} Set Location
      </div>
    )
  };

  return (
    <div className="sightings-explorer-container">
      <details open={open} onToggle={(e) => {setOpen(e.target.open)}}>
        <summary>Sightings near {site || "somewhere"}</summary>
        <div className="details-container">
          <TaxonInput taxon={taxon} setTaxon={(taxon) => {setTaxon(taxon); setNbnData({});}} />
          <RadiusInput kmRadius={kmRadius} handleRadiusChange={handleRadiusChange} />
          <MonthInput month={month} setMonth={(month) => {setMonth(month); setNbnData({});}} />
          <SetLocation site={site} />
        </div>
        <div className="sightings-go-container">
          <button
            className="sightings-go-button nbn-go-button"
            onClick={() => {
              setLoading(true);
              setOpen(false);
              fetchNbnSightings(
                { lat, lon, kmRadius, taxon, month, queryType: "groups" },
                setNbnData,
                setLoading
              );
            }}
          ></button>
          <button
            className="sightings-go-button inat-go-button"
            onClick={() => {
              setLoading(true);
              setOpen(false);
              fetchInatSightings({ lat, lon, kmRadius, taxon, month }, setInatData, setLoading);
            }}
          ></button>
        </div>
      </details>
      {<LoadingLogo show={loading} />}
      {!!inatData && !!Object.keys(inatData).length && (
        <InaturalistSightings
          inatData={inatData}
          setData={setInatData}
          mapRef={mapRef}
          setPopupContent={setPopupContent}
          setPopupLngLat={setPopupLngLat}
          setLoading={setLoading}
        />
      )}
      {!!nbnData && !!Object.keys(nbnData).length && (
        <NbnSightings
          nbnData={nbnData}
          setData={setNbnData}
          setTaxon={setTaxon}
          setLoading={setLoading}
          kmRadius={kmRadius}
          month={month}
        />
      )}
    </div>
  );
};

export default SightingsExplorer;
