import gql from "graphql-tag";
import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { addLayerToMap } from "../scripts/mapUtils";
import { mapContext } from "../context/mapContext";

const FEATURES_FOR_PLACETYPE = gql`
  query GetPlacesForPlacetype($category: String, $limit: Int!) {
    place_features(query: { properties: { group: $category } }, limit: $limit) {
      _id
      type
      geometry {
        type
        coordinates
      }
      properties {
        id
        type
        group
        markersize
        site
        wikiurl
        adurl
        adinfo
        topic
      }
    }
  }
`;

const FEATURES_FOR_TOPIC = gql`
  query GetPlacesForPlacetype($category: Int, $limit: Int!) {
    place_features(query: { properties: { topic: $category } }, limit: $limit) {
      _id
      type
      geometry {
        type
        coordinates
      }
      properties {
        id
        type
        group
        markersize
        site
        wikiurl
        adurl
        adinfo
        topic
      }
    }
  }
`;

const query_templates = {
  topic: FEATURES_FOR_TOPIC,
  placetype: FEATURES_FOR_PLACETYPE,
}

const resultsPerPage = 10000;


function GetPlacesForPlacetype({ categoryType, setLoaded, category }) {
  const { mapRef, setPopupLngLat, setPopupContent, showExplorer } = useContext(mapContext);
  const map = mapRef.current;
  
  const variables = {
    category,
    limit: resultsPerPage,
  };

  const { data, error, loading } = useQuery(query_templates[categoryType], {
    variables,
  });
  useEffect(() => {
    if (!error && !loading && data) {
      setLoaded(true);
    }
  });

  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>encountered an error: {error.message}</div>;
  }
  addLayerToMap(map, data.place_features, category, categoryType, setPopupLngLat, setPopupContent, showExplorer);
  return <div>Loaded.</div>;
}

export default GetPlacesForPlacetype;
