import React, { useState, useContext } from "react";
import { PLACETYPE_COLOURS } from "../scripts/constants";
import GetPlacesForPlacetype from "./GetPlacesForPlacetype";
import { mapContext } from "../context/mapContext";
import { TOPIC_NAMES, TOPIC_COLOURS } from "../scripts/topic_constants";

const FILTER_PLACETYPES = [
  "Nature Reserves",
  "Sites of Special Scientific Interest",
  "Country Parks",
  "Gardens",
  "Historic",
  "Woodlands",
  "Wildlife Trusts",
  "RSPB",
  "National Trust",
];

const FILTER_TOPICS = [
    "3", // Coastal birds west
    "9", // Coastal birds east
    "13", // Coastal birds misc I
    "23", // Coastal birds misc II
    "0", // English inland water birds
    "11", // Sort of wet areas
    "18", // Meadow birds by the coast
    "19", // Urban water birds
    "24", // Garden birds, near water
    "5", // Garden birds
    "1", // Countryside birds
    "20", // Birds of prey
    "7", // Woodland birds I.
    "16", // Woodland birds II
    "8", // Midlands birds
    "25", // Scottish birds
    "22", // Bees & Scottish mammals
    "21", // Butterflies, general
    "2", // Butterflies, southern
    "14", // Butterflies, northern
    "17", // Moths
    "4", // Damselflies & dragonflies
    "6", // Egrets, hazel dormouse & various bats
    "10", // Heathland
    "15", // Misc. garden creatures
    "12", // London
  ]

const orderedPlaces = {
  topic: FILTER_TOPICS,
  placetype: FILTER_PLACETYPES,
}

const colorMaps = {
  topic: TOPIC_COLOURS,
  placetype: PLACETYPE_COLOURS,
};

const nameMaps = {
  topic: TOPIC_NAMES,
  placetype: Object.fromEntries(FILTER_PLACETYPES.map((d) => [d, d])),
}


function hideAllLayers(toggles, setToggles, map, filterKeys) {
  const firstChecked = Object.values(toggles)[0];
  const newToggles = Object.fromEntries(Object.keys(toggles).map((k) => [k, !firstChecked]));
  setToggles(newToggles);

  filterKeys.filter((placetype) => map.getLayer(placetype)).forEach((placetype) => {
    map.setLayoutProperty(placetype, "visibility", newToggles[placetype] ? "visible" : "none");
  });
}

const FilterCategoryCheckbox = ({ category, categoryType, toggles, setToggles }) => {
  const { mapRef } = useContext(mapContext);
  const map = mapRef.current;

  const [loaded, setLoaded] = useState(false);
  const updateChecked = () => {
    let newToggles = { ...toggles };
    newToggles[category] = !toggles[category];
    setToggles(newToggles);

    if (loaded) {
      map.setLayoutProperty(category, "visibility", newToggles[category] ? "visible" : "none");
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id={`input-${category}`}
        checked={toggles[category]}
        key={category}
        onChange={() => {
          updateChecked();
        }}
      ></input>
      <label
        style={{ backgroundColor: colorMaps[categoryType][category] }}
        htmlFor={`input-${category}`}
      >
        <span className="filterName">{nameMaps[categoryType][category]}</span>
      </label>
      {!loaded && toggles[category] && (
        <GetPlacesForPlacetype category={category} setLoaded={setLoaded} map={map} categoryType={categoryType}/>
      )}
    </>
  );
};

const VisibilitySwitch = ({ filterActive, filterToggles, setFilterToggles, filterKeys }) => {
  const { mapRef } = useContext(mapContext);

  let vsStyle = {};
  const firstChecked = Object.values(filterToggles)[0];
  if (firstChecked) {
    vsStyle.borderRadius = "22px 0px";
    vsStyle.height = "17px";
  } else {
    vsStyle.borderRadius = "32px 0px";
    vsStyle.height = "10px";
  }

  if (!filterActive) {
    vsStyle.right = "6px";
    vsStyle.visibility = "hidden";
    vsStyle.transform = "rotate(90deg)";
    vsStyle.opacity = 0;
  } else {
    vsStyle.right = "61px";
    vsStyle.visibility = "visible";
    vsStyle.transform = "rotate(45deg)";
    vsStyle.opacity = 1;

    return (
      <div
        id="visibilitySwitch"
        style={vsStyle}
        onClick={() => {
          hideAllLayers(filterToggles, setFilterToggles, mapRef.current, filterKeys);
        }}
      ></div>
    );
  }
};

const SearchOptions = () => {
  const [show, setShow] = useState(false);
  const { showExplorer } = useContext(mapContext);

  const soStyle = {
    visibility: show ? "visible" : "hidden",
    height: show ? '87px' : '0px',
  };
  const mgStyle = {
    transform: show ? 'translate(0px, -4px) rotate(45deg)' : 'translate(-2px, -2px) rotate(0deg)',
  };
  const iconStyle = {
    backgroundImage: show ? 'linear-gradient(#fff, rgb(233, 241, 241))' : 'none',
    borderRadius: show ? 'none' : '45px',
  };

  return (
    <>
      <button id="mainSearchIcon" onClick={() => {console.log(show); setShow(!show)}} style={iconStyle}>
        <div id="magnifying-glass" style={mgStyle}></div>
      </button>
      <div id="search-options" style={soStyle}>
        <div
          className="search-option"
          id="search-option-species"
          onClick={() => {setShow(false); showExplorer('sightings')}}
          // onClick="displaySpeciesLocator();"
        >
          &#x1F9D0; Species sightings
        </div>
        <div
          className="search-option"
          id="search-option-location"
          onClick={() => {setShow(false); showExplorer('search')}}
          // onClick="displayPlaceSearch();"
        >
          &#x1F50D; Search map
        </div>
        <div
          className="search-option"
          id="search-option-isochrone"
          onClick={() => {setShow(false); showExplorer('isochrone')}}
          // onClick="displayMitm();"
        >
          &#x1F697; Meet in the middle
        </div>
      </div>
    </>
  );
};

const MainFilterIcon = ({ filterActive, setFilterActive }) => {
  return (
    <button
      id="mainFilterIcon"
      onClick={() => {
        setFilterActive(!filterActive);
      }}
    >
      <div id="filter-icon-drip" style={{ top: filterActive ? "14px" : "5px" }}></div>
      <div id="filter-icon" style={{ top: filterActive ? "8px" : "12px" }}></div>
    </button>
  );
};

const PlaceFilters = ({ categoryType }) => {
  const [filterActive, setFilterActive] = useState(true);
  const filterKeys = orderedPlaces[categoryType];
  const initialToggles = Object.fromEntries(filterKeys.map((d) => [d, false]));
  const [filterToggles, setFilterToggles] = useState(initialToggles);

  const filterGroupStyle = {
    visibility: filterActive ? "visible" : "hidden",
    transform: filterActive ? "scaleY(1)" : "scaleY(0)",
  };

  return (
    <>
      <SearchOptions />
      <VisibilitySwitch
        filterActive={filterActive}
        filterToggles={filterToggles}
        setFilterToggles={setFilterToggles}
        filterKeys={filterKeys}
      />
      <MainFilterIcon filterActive={filterActive} setFilterActive={setFilterActive} />

      <nav id="filter-group" className="filter-group" style={filterGroupStyle}>
        <div id="filterBody"></div>
        {filterKeys.map((category) => (
          <FilterCategoryCheckbox
            category={category}
            categoryType={categoryType}
            toggles={filterToggles}
            setToggles={setFilterToggles}
            key={category}
          />
        ))}
        {/* <div id="pub-container"><a id="loadPubsLink" className="abox" style="background-color: none;"
                onClick="loadPubFn()">Show
                Pubs</a></div> */}
      </nav>
    </>
  );
};

export default PlaceFilters;
