export const URL_PREFIXES = {
  BBOWT: "",
  "National Trust": "https://www.nationaltrust.org.uk/",
  //    'National Trust':'https://www.nationaltrust.org.uk/',
  Woodlands: "",
  "Local Nature Reserve (England)":
    "https://designatedsites.naturalengland.org.uk/SiteLNRDetail.aspx?SiteCode=L",
  "National Nature Reserve (England)": "",
  RSPB: "https://www.rspb.org.uk/reserves-and-events/reserves-a-z/",
  Garden: "",
  "SSSI (England)":
    "https://designatedsites.naturalengland.org.uk/SiteDetail.aspx?SiteCode=S",
  "English Heritage": "https://www.english-heritage.org.uk/visit/places/",
  Historic: "",
  "Country Park (England)": "",
  "Wildlife Trust Reserve": "https://www.wildlifetrusts.org",
  Woodland: "https://www.woodlandtrust.org.uk/visiting-woods/woods/",
  "Forestry England": "https://www.forestryengland.uk",
  "National Trust Scotland": "https://www.nts.org.uk/visit/places/",
  Pubs: "",
  "Wildfowl & Wetlands Trust": "https://www.wwt.org.uk",
  "National Nature Reserve (NI)": "",
  "SSSI (NI)": "",
  "Country Park (Scotland)": "https://sitelink.nature.scot/site/",
  "National Nature Reserve (Scotland)": "",
  "Scottish Natural Heritage Reserve": "",
  "SSSI (Scotland)": "https://sitelink.nature.scot/site/",
  "Country Park (Wales)": "",
  "Local Nature Reserve (Wales)": "",
  "National Nature Reserve (Wales)": "",
  "SSSI (Wales)": "",
  "National Trust Land": "",
};

export const URL_TEXT = {
  BBOWT: "",
  "National Trust": "National Trust",
  Woodlands: "",
  "Local Nature Reserve (England)": "Natural England",
  "National Nature Reserve (England)": "NNR info",
  RSPB: "RSPB Website",
  Garden: "",
  "SSSI (England)": "Natural England",
  "English Heritage": "English Heritage",
  Historic: "",
  "Country Park (England)": "Website",
  "Wildlife Trust Reserve": "Wildlife Trust",
  Woodland: "Woodland Trust",
  "Forestry England": "Forestry England",
  "National Trust Scotland": "NTS Website",
  "Wildfowl & Wetlands Trust": "WWT website",
  "National Nature Reserve (NI)": "ni.gov.uk",
  "SSSI (NI)": "ni.gov.uk",
  "Country Park (Scotland)": "NatureScot",
  "National Nature Reserve (Scotland)": "NNR Scot",
  "Scottish Natural Heritage Reserve": "snh.gov.uk",
  "SSSI (Scotland)": "NatureScot",
  "Country Park (Wales)": "",
  "Local Nature Reserve (Wales)": "",
  "National Nature Reserve (Wales)": "",
  "SSSI (Wales)": "",
  "National Trust Land": "",
};

export const PLACETYPE_NAMES = {
  BBOWT: "Wildlife Trusts",
  "National Trust": "National Trust",
  "National Trust Parking": "National Trust",
  Woodlands: "Woodlands",
  "Local Nature Reserve (England)": "Nature Reserves",
  RSPB: "RSPB",
  Garden: "Gardens",
  "SSSI (England)": "Sites of Special Scientific Interest",
  "English Heritage": "Historic",
  "National Nature Reserve (England)": "Nature Reserves",
  Historic: "Historic",
  "Country Park (England)": "Country Parks",
  "Wildlife Trust Reserve": "Wildlife Trusts",
  Woodland: "Woodlands",
  "Forestry England": "Woodlands",
  "National Trust Scotland": "National Trust",
  "National Trust Land": "National Trust",
  "Wildfowl & Wetlands Trust": "Nature Reserves",
  "National Nature Reserve (NI)": "Nature Reserves",
  "SSSI (NI)": "Sites of Special Scientific Interest",
  "Country Park (Scotland)": "Country Parks",
  "National Nature Reserve (Scotland)": "Nature Reserves",
  "Scottish Natural Heritage Reserve": "Nature Reserves",
  "SSSI (Scotland)": "Sites of Special Scientific Interest",
  "Country Park (Wales)": "Country Parks",
  "Local Nature Reserve (Wales)": "Nature Reserves",
  "National Nature Reserve (Wales)": "Nature Reserves",
  "SSSI (Wales)": "Sites of Special Scientific Interest",
};

export const PLACETYPE_COLOURS = {
  Gardens: "#d279a8",
  Historic: "#a3a3a3",
  "National Trust": "#78527a",
  "Nature Reserves": "#81d5b6",
  RSPB: "#338fcc",
  "Sites of Special Scientific Interest": "#afb8cf",
  Woodlands: "#7C6354",
  "Country Parks": "#d9aa8c",
  "Wildlife Trusts": "#289e65",
};
