import React, { useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

const InaturalistPopup = ({ item }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
  }, [item]);
  const getTaxonName = (item) => {
    if (!item.taxon) {
      return "unknown";
    }
    if (!item.taxon.common_name) {
      return item.taxon.name;
    }
    return item.taxon.common_name.name;
  };
  return (
    <>
      <div
        onClick={() => {
          window.open(item.uri, "_system");
        }}
      >
        <img
          onLoad={() => {
            setShow(true);
          }}
          className="inatImage"
          src={item.photos[0].small_url}
          style={{ opacity: show ? 1 : 0 }}
        />
        {/* {!show && <div style={{ width: "100%", height: "100%", background: "#eee" }}></div>} */}
      </div>
      <div>
        <b>{getTaxonName(item)}</b>
        <br />
        {item.observed_on}
      </div>
    </>
  );
};

function addInatMarker(item, index, mapRef, setPopupContent, setPopupLngLat) {
  var obsDate = new Date(item.observed_on);

  var el = document.createElement("div");
  el.className = "inatMarker";

  if (new Date() - obsDate < 30 * 1000 * 60 * 60 * 24) {
    el.style.backgroundColor = "rgba(100,255,170,0.7)";
  }
  //el.onclick = "inatPopup(" + index + ")";
  //el.innerHTML = '<span>' + alList.length + '</span>';

  const inatMarker = new mapboxgl.Marker(el)
    .setLngLat([item.longitude, item.latitude])
    .addTo(mapRef.current);

  inatMarker.getElement().addEventListener("click", function(e) {
    setPopupContent(<InaturalistPopup item={item} />);
    setPopupLngLat([item.longitude, item.latitude]);
    e.stopPropagation();
  });

  return inatMarker;
}

// function inatPopup(inatObsIndex) {
//   if (currentInatPopup) {
//     currentInatPopup.remove();
//   }

//   var thisObs = inatObs[inatObsIndex];
//   var taxon = "unknown";
//   if ("taxon" in thisObs) {
//     if (thisObs.taxon.common_name) {
//       taxon = thisObs.taxon.common_name.name;
//     } else {
//       taxon = thisObs.taxon.name;
//     }
//   }

//   currentInatPopup = new mapboxgl.Popup()
//     .setLngLat([thisObs.longitude, thisObs.latitude])
//     .setHTML(
//       `<div onclick="window.open(\'${thisObs.uri}\', \'_system\');"><img class="inatImage" src="${thisObs.photos[0].small_url}"></div><div><b>${taxon}</b><br>${thisObs.observed_on}</div>`
//     )
//     .addTo(map);
// }

function removeInatMarkers(inatMarkers, setInatData, setPopupLngLat, setPopupContent, setInatMarkers) {
  inatMarkers.forEach((inatMarker) => {
    inatMarker.remove();
  });
  setInatMarkers([]);

  setInatData({});
  // setPopupLngLat(null);
  // setPopupContent([]);

  // if (currentInatPopup) {
  //   currentInatPopup.remove();
  // }
}

export const fetchInatSightings = async (params, setData) => {
  setData({});
  const { lat, lon, kmRadius, taxon, month } = params;

  var swlat = lat - kmRadius / 110.574;
  var swlng = lon - kmRadius / (111.32 * Math.cos((lat / 180) * Math.PI));
  var nelat = lat + kmRadius / 110.574;
  var nelng = lon + kmRadius / (111.32 * Math.cos((lat / 180) * Math.PI));

  var url = new URL("https://www.inaturalist.org/observations.json");
  url.searchParams.append("swlat", swlat);
  url.searchParams.append("swlng", swlng);
  url.searchParams.append("nelat", nelat);
  url.searchParams.append("nelng", nelng);
  url.searchParams.append("per_page", 200);
  url.searchParams.append("order_by", "observed_on");
  url.searchParams.append("has[]", "photos");

  if (month) {
    url.searchParams.append("month", parseInt(month));
  }

  if (taxon !== "ALL_SPECIES") {
    url.searchParams.append("taxon_name", taxon);
  }
  await fetch(url)
    .then((res) => res.json())
    .then((data) => {
      setData({ query: { lat, lon, kmRadius, taxon, month }, data });
    })
    .catch((err) => {
      setData({ error: err });
    });
};

const InaturalistSightings = ({
  inatData,
  setData,
  mapRef,
  setPopupContent,
  setPopupLngLat,
  setLoading,
}) => {
  const [inatMarkers, setInatMarkers] = useState([]);
  if (inatData.error) {
    return <div>{inatData.error.message}</div>;
  }
  
  useEffect(() => {
    setLoading(false);
    console.log(inatMarkers);
    let newInatMarkers = [...inatMarkers];
    inatData.data.forEach((d, idx) => {
      newInatMarkers.push(addInatMarker(d, idx, mapRef, setPopupContent, setPopupLngLat));
    });
    setInatMarkers([...newInatMarkers]);
  }, [inatData]);

  if (!inatMarkers.length) {
    return <></>;
  }

  return (
    <button
      className="remove-inat-markers"
      onClick={() => {
        removeInatMarkers(inatMarkers, setData, setPopupLngLat, setPopupContent, setInatMarkers);
      }}
    >
      Remove inaturalist markers
    </button>
  );
};

export default InaturalistSightings;
