import React from "react";
import "../../styles/loading_logo.css";

const OW_CIRCLE_LOCS = [
  {
    top: 13,
    left: 44.5,
    width: 24,
    height: 24,
    background: "rgb(126, 196, 149)",
  },
  {
    top: 24,
    left: 42,
    width: 10,
    height: 10,
    background: "rgb(72, 133, 80)",
  },
  {
    top: 25,
    left: 56.5,
    width: 23,
    height: 23,
    background: "rgb(67, 159, 71)",
  },
  {
    top: 36,
    left: 44.5,
    width: 28,
    height: 28,
    background: "rgb(72, 164, 102)",
  },
  {
    top: 48.2,
    left: 56.2,
    width: 23.5,
    height: 23.5,
    background: "rgb(60, 149, 40)",
  },
  {
    top: 48.5,
    left: 32.5,
    width: 19,
    height: 19,
    background: "rgb(61, 189, 75)",
  },
  {
    top: 60.5,
    left: 32.5,
    width: 25,
    height: 25,
    background: "rgb(49, 165, 72)",
  },
  {
    top: 60.5,
    left: 20.5,
    width: 24,
    height: 24,
    background: "rgb(66, 169, 70)",
  },
  {
    top: 60,
    left: 52.5,
    width: 8.5,
    height: 8.5,
    background: "rgb(94, 201, 109)",
  },
  {
    top: 60.5,
    left: 68.5,
    width: 24,
    height: 24,
    background: "rgb(87, 188, 91)",
  },
  {
    top: 72.4,
    left: 56.2,
    width: 28,
    height: 28,
    background: "rgb(65, 170, 85)",
  },
  {
    top: 72.2,
    left: 68.3,
    width: 15,
    height: 15,
    background: "rgb(86, 194, 105)",
  },
  {
    top: 84.2,
    left: 44.3,
    width: 17,
    height: 17,
    background: "rgb(52, 159, 118)",
  },
  {
    top: 84.2,
    left: 56.3,
    width: 24,
    height: 24,
    background: "rgb(80, 188, 99)",
  },
  {
    top: 84.2,
    left: 68.3,
    width: 16.2,
    height: 16.2,
    background: "rgb(49, 153, 79)",
  },
  {
    top: 84.3,
    left: 80.4,
    width: 28.3,
    height: 28.3,
    background: "rgb(58, 188, 158)",
  },
];

const LoadingLogo = ({ show }) => (
  <>
    {show && (
      <div id="outwhere-logo">
        {OW_CIRCLE_LOCS.map((ocl, idx) => (
          <div
            id={`oc${idx + 1}`}
            className={"ow-circle"}
            style={{ ...ocl, animationDelay: `${idx / 32 - 0.75}s` }}
            key={idx}
          ></div>
        ))}
      </div>
    )}
  </>
);

export default LoadingLogo;
