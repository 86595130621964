import React from "react";
import pSBC from "shade-blend-color";
import { URL_PREFIXES, URL_TEXT } from "../scripts/constants";

const copyShareLink = (featId, elat, elon) => {
  console.log("copyShareLink", featId, elat, elon);
};
const sightingsAndRadCircle = (feature, showExplorer) => {
  const [lon, lat] = feature.geometry.coordinates;
  const { site, group } = feature.properties;
  showExplorer("sightings", { lat, lon, site, group });
};

const MapPopupContent = ({ feature, layerColors, showExplorer }) => {
  const { site, type, adinfo, adurl, wikiurl, id, group } = feature.properties;
  const colour1 = pSBC(0.5, layerColors[group], "rgba(255,255,255,0.4)", true);
  const color2 = pSBC(0.5, layerColors[group], "rgba(50, 50, 50, 0.9)", true);
  const [elon, elat] = feature.geometry.coordinates;
  // console.log(feature.properties);
  return (
    <>
      <b style={{color: color2}} >{site}</b>
      <br />
      <div
        className="popup-info-box"
        style={{ background: colour1, marginTop: "5px", paddingBottom: "4px" }}
      >
        {type}
        <br />
        {adinfo && (
          <>
            <span className="popup-info-text" dangerouslySetInnerHTML={{ __html: adinfo }} />
            <br />
          </>
        )}
        {adurl && adurl !== 'null' && (
          <>
            <a
              className="abox"
              style={{ background: color2 }}
              href={URL_PREFIXES[type] + adurl}
              target="_blank"
            >
              {URL_TEXT[type]}
            </a>
            <br />
          </>
        )}
        {wikiurl && (
          <>
            <a className="abox" href={`https://en.wikipedia.org/wiki/${wikiurl}`} target="_blank">
              Wikipedia
            </a>
            <br />
          </>
        )}
        <a
          className="othera"
          style={{ color: "#EA4335" }}
          href={`https://www.google.com/maps/search/?api=1&query=${elat},${elon}`}
          target="_blank"
        >
          Google Maps
        </a>
        <span className="othera" style={{ color: "#777" }}>
          {" "}
          |{" "}
        </span>
        <a
          className="othera"
          style={{ color: "#274" }}
          id={`share${id}`}
          onClick={() => {
            copyShareLink(id, elat, elon);
          }}
        >
          Share Link
        </a>
        <br />
        <button
            className="view-sightings"
            style={{ color: color2 }}
            onClick={() => {
              sightingsAndRadCircle(feature, showExplorer);
            }}
          >
            Explore Sightings
          </button>
      </div>
    </>
  );
};

export default MapPopupContent;

//   if (layerType === "topic") {
//     description +=
//       '<div class="popInfoBox" style="font-family:Alegreya Sans;background-color:' +
//       pSBC(
//         0.75,
//         TOPIC_COLOURS[e.features[0].properties["topic"]],
//         false,
//         true
//       ) +
//       ';margin-top:5px;"><b>' +
//       TOPIC_NAMES[e.features[0].properties.topic] +
//       "</b><br><i>" +
//       "placeholder" +
//       // ts_dict[e.features[0].properties.id] +
//       "</i></div>";
//   }
// };
